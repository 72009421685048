class RecipeService {
  constructor() {

  }
  
  
    fetchOrderConfirmRecommendedRecipes() {
      
        return recipeRef.orderByChild("is_nextorder").equalTo(true).once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }

  fetchFeaturedRecipes() {
      
        return recipeRef.orderByChild("is_featured").equalTo(true).once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }
  
    fetchRecommendedRecipes() {
      
        return recipeRef.orderByChild("is_recommended").equalTo(true).once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }
  
      fetchAllRecipes() {
      
        return recipeRef.once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }
     fetchRecipeById(id) {
      
        return recipeRef.child(id).once('value').then(function(snapshot) {
      return snapshot.val();
  }, function(error) {
  // The Promise was rejected.
  console.error(error);
});

  }


}
